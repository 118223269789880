import styled from 'styled-components'
import { Box } from '@makerdao/ui-components-core'

const OneColumnLayout = styled(Box).attrs(props => ({
  maxWidth: props.maxWidth || '640px',
  minHeight: props.minHeight || '400px',
  mx: props.mx || 'auto',
  mt: props.mt || '5rem',
}))``

const LayoutHeader = styled(Box).attrs(props => ({
  mx: props.mx || 'auto',
  mb: props.mb || '4.8rem',
  textAlign: props.textAlign || 'left',
}))`
  h1 {
    text-align: center;
  }
  & h2 {
    text-align: center;
    margin-bottom: 1.3rem;
  }

  & p:not(:last-child) {
    margin-bottom: 1.6rem;
  }
`

export { OneColumnLayout, LayoutHeader }
