import React from 'react'
import styled from 'styled-components'
import { FormattedMessage as FM } from 'react-intl'

import Layout from '../components/layout'
import { Flex } from '@makerdao/ui-components-core'
import { OneColumnLayout, LayoutHeader } from '../components/display/oneColumn'
import { getGenericPageTitle, getMessage, messages } from '../i18n'

const Contact = styled(Flex).attrs(() => ({
  flexGrow: '1',
  py: '2rem',
  justifyContent: 'flex-start',
  alignItems: 'center',
}))`
  & + & {
    border-top: 1px solid #d7d7d7;
  }

  h3 {
    margin-right: auto;
    font-size: 2rem;
    font-weight: normal;
    letter-spacing: 0.03rem;
  }

  a {
    font-size: 1.8rem;
  }
`

const ContactUs = ({ pageContext: { locale } }) => (
  <Layout
    locale={locale}
    p="s"
    meta={{
      title:
        messages[locale]['contact.meta.title'] ||
        getGenericPageTitle('Contact', locale),
      description: getMessage('contact.meta.description', locale),
    }}
  >
    <OneColumnLayout maxWidth="900px">
      <LayoutHeader textAlign="center">
        <h1>
          <FM id="contact.headline" />
        </h1>
        <p>
          <FM id="contact.subheadline" />
        </p>
      </LayoutHeader>
    </OneColumnLayout>
  </Layout>
)

export default ContactUs
